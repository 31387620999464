<template>
  <span class="radio__wrapper">
    <input type="radio" :name="props.name" :value="props.value" class="radio" :class="{ 'cursor-pointer': !props.disabled, 'cursor-not-allowed': props.disabled }" :disabled="props.disabled" v-model="props.modelValue" @click="checkChanged($event)" />
  </span>
</template>
<script setup>

const props = defineProps({
  value: String,
  name: String,
  modelValue: Boolean | String,
  disabled: Boolean,
  preventDefault: Boolean
});

const emit = defineEmits(['update:modelValue', 'click']);

const checkChanged = ($event) => {
  
  if (props.preventDefault) {
    $event.preventDefault();
    emit('click', $event.target.value);
  }
  else {
    emit('update:modelValue', $event.target.value);
    emit('click', $event.target.value);
  }
}

</script>
<style lang="postcss">
.radio {
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  margin-left: 0;
  margin-right: 1.75em;
  line-height: 1em;
  vertical-align: middle;
  outline: 0;
  -moz-appearance: initial;

&__wrapper {
  position: relative;
  display: inline-block;
  width: 1.2em;
  height: 1.2em;
  line-height: 1em;
}

  &::before {
    position: absolute;
    content: "";
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    vertical-align: middle;
    font-size: 1em;
    width: 1.2em;
    height: 1.2em;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 50%;
    @apply border-2 border-neutral-200 bg-neutral-100;
  }

  &::after {
    position: absolute;
  }

  &:checked::before {
    font-style: normal;
    font-weight: normal;
    speak: never;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "";
    @apply bg-neutral-50;
  }

  &:checked::after {
    content: '';
    font-style: normal;
    font-weight: normal;
    speak: never;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    left: 0.25em;
    top: 0.25em;
    width: 0.7em;
    height: 0.7em;
    border-radius: 50%;
    @apply bg-success-500;
  }
}
</style>